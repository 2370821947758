// Package Imports
import * as FlexWebChat from "@twilio/flex-webchat-ui";
import { AppConfig } from "@twilio/flex-webchat-ui";
import { useCallback, useEffect, useRef, useState } from "react";
import { appConfig } from "./webchat-appConfig";

// Component Imports
import Interactives from "./components/Interactives";
import { EndChatModal } from "./components/instances/EndChatModal";

// Const Imports
import { managerStringOverrides } from "./consts";
import { livVendedoraUrls } from "./livVendedoraUrls";

// Const Imports
import { EntryPoint } from "./components/molecules/EntryPoint";
import { MessageInput } from "./components/organisms/MessageInput";
import { reducers } from "./store";

import axios from "axios";
import { MainContainer } from "./components/atoms/MainContainer";
import { HeaderInstance } from "./components/instances/HeaderInstance";
import LivButtons from "./components/instances/LivButtons";
import { MessageListItemInstance } from "./components/instances/MessageListItemInstance";
import generateRandomString from "./utils/generateRandomString";
import { EngagementStage } from "./types/engagementStage";
import InitialMenuButtons from "./components/instances/InitialMenuButtons";


// Component
const App = ({ manager }) => {
  // State
  const [updatedCustomManager, setUpdatedCustomManager] = useState(false);
  const [visible, setVisible] = useState(false);
  const livButtonsRestore = useRef(null);

  // Liv Vendedora
  const timeLiv = useCallback(() => {
    const actualPage = livVendedoraUrls.find(
      ({ url }) => url === window.location.href
    );
    return actualPage?.timePop; 
  }, []);

  
  // console.log(timePop)
  const timeToStartFirstTime = timeLiv();
  const timeToStartSecondTime = 600000;
  const maxTimesToStartPerDay = 2;

  

  const getPageUrl = useCallback(() => {
    const actualPage = livVendedoraUrls.find(
      ({ url }) => url === window.location.href
    );

    return actualPage;
  }, []);

  const callLivVendedora = useCallback(() => {
    if (!manager) {
      return;
    }

    if (
      manager.store.getState().flex.session.engagementStage ===
      EngagementStage.IN_ENGAGEMENT
    ) {
      return;
    }

    if (livButtonsRestore.current) {
      livButtonsRestore.current();
    } else {
      FlexWebChat.MessageList.Content.add(
        <LivButtons key="liv-buttons" livButtonsRestore={livButtonsRestore} />
      );

      FlexWebChat.MessageList.Content.remove("initial-menu-buttons");

      FlexWebChat.Actions.addListener("afterSendMessage", () => {
        FlexWebChat.MessageList.Content.remove("liv-buttons");
      });
    }

    AppConfig.current().context.flag = 'liv_vendedora_proativa';

    FlexWebChat.MessagingCanvas.defaultProps.predefinedMessage.body =
      getPageUrl().text;

    FlexWebChat.Actions.invokeAction("ToggleChatVisibility");
  }, [manager, getPageUrl]);

  const createNewLivVendedoraKey = useCallback(() => {
    const livVendedora = {
      dailyTries: 1,
      key: generateRandomString(),
      dayCreated: new Date().getDate(),
    };

    localStorage.setItem("liv_vendedora", JSON.stringify(livVendedora));
  }, []);

  const handleLivVendedora = useCallback(() => {
    if (!getPageUrl()) return;

    const key = localStorage.getItem("liv_vendedora");

    if (key) {
      const livVendedoraLocalStorage = JSON.parse(key);

      setTimeout(() => {
        if (livVendedoraLocalStorage.dailyTries < maxTimesToStartPerDay) {
          localStorage.setItem(
            "liv_vendedora",
            JSON.stringify({
              ...livVendedoraLocalStorage,
              dailyTries: livVendedoraLocalStorage.dailyTries + 1,
            })
          );

          callLivVendedora();
        } else {
        }
      }, timeToStartSecondTime);
    } else {
      setTimeout(() => {
        createNewLivVendedoraKey();
        callLivVendedora();
      }, timeToStartFirstTime);

      setTimeout(() => {
        const key = localStorage.getItem("liv_vendedora");
        const livVendedoraLocalStorage = JSON.parse(key);

        if (livVendedoraLocalStorage.dailyTries < maxTimesToStartPerDay) {
          localStorage.setItem(
            "liv_vendedora",
            JSON.stringify({
              ...livVendedoraLocalStorage,
              dailyTries: livVendedoraLocalStorage.dailyTries + 1,
            })
          );

          callLivVendedora();
        }
      }, timeToStartSecondTime);
    }
  }, [
    callLivVendedora,
    createNewLivVendedoraKey,
    getPageUrl,
    timeToStartSecondTime,
  ]);

  // Functions
  const getManager = useCallback(async () => {
    // Init Flex Webchat Manager
    manager.store.replaceReducer(reducers);
    FlexWebChat.MessagingCanvas.Content.add(
      <EndChatModal
        key="EndChatModal"
        runtimeDomain={AppConfig.current().runtimeDomain}
        onClick={() => console.log("EndChatModal")}
        livButtonsRestore={livButtonsRestore}
      />,
      { sortOrder: -1, align: "end" }
    );

    const {
      entryPointTagLine,
      predefineChatMessageAuthorName,
      predefinedChatMessageBody,
      welcomeMessage,
      messageCanvasTrayContent,
      messageCanvasTrayButton,
    } = managerStringOverrides;

    manager.strings.EntryPointTagline = entryPointTagLine;
    manager.strings.WelcomeMessage = welcomeMessage;
    manager.strings.MessageCanvasTrayContent = messageCanvasTrayContent;
    manager.strings.MessageCanvasTrayButton = messageCanvasTrayButton;
    manager.strings.PredefinedChatMessageAuthorName =
      predefineChatMessageAuthorName;
    manager.strings.PredefinedChatMessageBody = predefinedChatMessageBody;

    // Append custom Interactives Component
    FlexWebChat.MessageList.Content.add(
      <Interactives
        key="interactives"
        manager={manager}
        colorTheme={appConfig.colorTheme.overrides.Interactives}
      />
    );
    FlexWebChat.MainHeader.Content.replace(<HeaderInstance key="custom" />);
    FlexWebChat.EntryPoint.Content.replace(
      <EntryPoint key="entrypoint" manager={manager} />
    );
    FlexWebChat.MessageInput.Content.replace(
      <MessageInput key="messageinput" />
    );

    FlexWebChat.MessageListItem.Content.replace(
      <MessageListItemInstance key="messagelistitem" />
    );

    FlexWebChat.MessageList.Content.add(
      <InitialMenuButtons key={"initial-menu-buttons"} manager={manager} />
    );

    // Set manager on state
    setUpdatedCustomManager(true);
  }, [manager]);

  const clearLocalStorage = useCallback(() => {
    const key = localStorage.getItem("liv_vendedora");

    if (!key) return;

    const dayCreated = JSON.parse(key).dayCreated;
    const today = new Date().getDate();

    if (dayCreated !== today) {
      localStorage.removeItem("liv_vendedora");
    }
  }, []);

  const init = useCallback(async () => {
    await getManager();
    clearLocalStorage();
    if (manager.store.getState().flex.session.isEntryPointExpanded) {
      await FlexWebChat.Actions.invokeAction("ToggleChatVisibility");
    }

    setVisible(true);

    await handleLivVendedora();
  }, [clearLocalStorage, getManager, handleLivVendedora, manager.store]);

  const setOnUnload = useCallback(() => {
    window.onunload = () => {
      localStorage.removeItem("twilio-flex-cf");
    };
  }, []);

  useEffect(() => {
    init();
    setOnUnload();
  }, [init, setOnUnload]);

  return (
    <>
      {updatedCustomManager && (
        <FlexWebChat.ContextProvider manager={manager}>
          <MainContainer visible={visible}>
            <FlexWebChat.RootContainer />
          </MainContainer>
        </FlexWebChat.ContextProvider>
      )}
    </>
  );
};

export default App;
